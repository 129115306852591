import {
	AddToCart,
	AddToPlaylist,
	AddToQueue,
	Play,
} from "@components/interaction";
import { renderArtistNames } from "@components/shared/Artists/ArtistNames";
import { Release } from "@models/release";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import {
	Actions,
	Badges,
	CardLink,
	ImageWrapper,
	Meta,
	ReleaseName,
	Wrapper,
} from "./FeatureRelease.style";

interface Props {
	release: Release;
}

const FeatureRelease: React.FC<Props> = ({ release }) => {
	const { t } = useTranslation("translation");
	const exclusive = `${t("Exclusive")}`;
	const preOrder = `${t("PreOrder")}`;
	return (
		<Wrapper>
			<ImageWrapper>
				{(release.exclusive || release.pre_order || release.is_hype) && (
					<Badges>
						{release.exclusive && <div className="exclusive">{exclusive.toUpperCase()}</div>}
						{release.pre_order && <div className="preorder">{preOrder.toUpperCase()}</div>}
						{release.is_hype && <div className="hype">HYPE</div>}
					</Badges>
				)}
				<Link
					href={`/release/${release?.slug}/${release?.id}`}
					prefetch={false}
					title={release?.name}
					className="artwork"
				>
					<Image
						src={release.image.uri}
						alt={release.name}
						width="590"
						height="590"
						sizes="100vw"
						style={{
							width: "100%",
							height: "auto",
						}}
					/>
				</Link>
			</ImageWrapper>
			<Meta>
				<Link
					href={`/release/${release?.slug}/${release?.id}`}
					prefetch={false}
					legacyBehavior
				>
					<ReleaseName title={release?.name}>{release?.name}</ReleaseName>
				</Link>
				{release.artists &&
					release.artists.length > 0 && (
					<CardLink>{ renderArtistNames(release.artists, { location: "Featured Release" }) }</CardLink>
				)}
				<Actions>
					<Play releaseId={release.id} variant="rounded" />
					<AddToQueue releaseId={release.id} />
					<AddToPlaylist releaseId={release.id} />
					<AddToCart release={release} />
				</Actions>
			</Meta>
		</Wrapper>
	);
};

export default FeatureRelease;
