import { TopTracks } from "@components/Panels";
import { FeatureRelease } from "@components/cards/FeatureRelease";
import {
	ALLOWED_VIEW_TYPES,
	HeadingWithBreadcrumb,
} from "@components/headings/HeadingWithBreadcrumb";
import { GridSlider } from "@components/sliders/GridSlider";
import { HeadingH2 } from "@components/typography/Typography.style";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { Label } from "@models/label";
import { Release } from "@models/release";
import { Track } from "@models/track";
import { device } from "@styles/theme";
import { useTranslation } from "next-i18next";
import { MainSection, RightSeciton, Wrapper } from "./LabelPage.style";

interface Props {
	label?: Label;
	latestReleases?: Release[];
	popularReleases?: Release[];
	top10Tracks?: Track[];
}

const LabelPage: React.FC<Props> = ({
	label,
	latestReleases = [],
	popularReleases = [],
	top10Tracks = [],
}) => {
	const isMd = useMediaQuery({ query: device.md });
	const { t } = useTranslation("translation");

	const featureRelease = latestReleases[0];
	const releases = latestReleases.slice(1);
	const showReleases = latestReleases.length > 0;

	const rightSectionComponents = () => (
		<>
			<TopTracks
				title={<HeadingH2>Top Ten Tracks</HeadingH2>}
				location="Top Ten Tracks"
				tracks={top10Tracks}
				isPanelList={false}
			/>
		</>
	);

	return (
		<Wrapper>
			<MainSection>
				<HeadingWithBreadcrumb
					location={`Label - ${label?.name}`}
					id={label?.id || 0}
					slug={label?.slug || ""}
					title={label?.name || ""}
					image={label?.image?.uri}
					viewType={ALLOWED_VIEW_TYPES.LABEL}
					showSocial
					showFollow
					showTabs={showReleases}
				/>

				{showReleases && (
					<>
						<FeatureRelease release={featureRelease} />

						{releases.length > 0 && (
							<GridSlider
								location={`Label - ${label?.name} - Latest Releases`}
								type="releases"
								title={t("LatestReleases")}
								data={releases}
								rows={2}
							/>
						)}

						{popularReleases.length > 0 && (
							<GridSlider
								location={`Label - ${label?.name} - Popular Releases`}
								type="releases"
								title={t("PopularReleases")}
								data={popularReleases}
								rows={2}
							/>
						)}
					</>
				)}

				{!isMd && <RightSeciton>{rightSectionComponents()}</RightSeciton>}
			</MainSection>
			{isMd && <RightSeciton>{rightSectionComponents()}</RightSeciton>}
		</Wrapper>
	);
};

export default LabelPage;
