import styled from "styled-components";

export const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;

  padding: 8px 0;
`;

export const ReleaseName = styled.a`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.017em;

  color: ${(props) => props.theme.colors.neutrals.primary.white};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const CardLink = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.neutrals.primary.white};

  a:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Meta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Badges = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  margin: 8px;
  display: flex;
  flex-direction: column;

  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  color: black;
  width: 100px;

  div {
    padding: 0 8px;
  }

  div.hype {
    background: ${(props) => props.theme.colors.blue[500].accent};
  }

  div.exclusive {
    background: ${(props) => props.theme.colors.green[300].accent};
  }

  div.preorder {
    background: ${(props) => props.theme.colors.yellow[300].accent};
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  padding: 8px 8px 8px 0;
  float: left;
  position: relative;

  @media ${(props) => props.theme.device.sm} {
    max-width: 250px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;

  @media ${(props) => props.theme.device.sm} {
    flex-direction: row;
  }
`;
